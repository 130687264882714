<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        
          <v-text-field
            v-model="model.rechungsbetrag"
            label="monatliche Lizenzgebühr (netto)"
            prefix="€"
         ></v-text-field>
          <v-text-field
            v-model="model.rechnungAnEmailAdresse"
            label="Rechnung an E-Mail"
         ></v-text-field>
         <v-switch
              v-model="model.sepaMandat"
              label="SEPA Mandat vorhanden"
    ></v-switch>
    <v-text-field
            v-model="model.kontoinhaber"
            label="Kontoinhaber"            
         ></v-text-field>
    <v-text-field v-if="model.sepaMandat"
          v-model="model.nameZahlungsdienstleisters"
          label="Name des Zahlungsdienstleisters"
        ></v-text-field>
        <v-text-field v-if="model.sepaMandat"
          v-model="model.iban"
          label="IBAN"
        ></v-text-field>
        <v-text-field v-if="model.sepaMandat"
          v-model="model.bic"
          label="BIC"
        ></v-text-field>


        <v-layout row wrap>
            <v-menu
          v-model="fromDateMenuSepaMandatUnterschriebenAm"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
        <template v-slot:activator="{ on }">
            <v-text-field              
                :value="fromDateDispSepaMandatUnterschriebenAm"
                label="Sepa Unterschrieben am"
                readonly
                prepend-icon="mdi-calendar"
                v-on="on"
            ></v-text-field>
          </template>
            <v-date-picker
              locale="de-in"
              :min="minDate"
              v-model="model.sepaMandatUnterschriebenAm"
              no-title
              @input="fromDateMenuSepaMandatUnterschriebenAm = false"
            ></v-date-picker>
          </v-menu>
        </v-layout>  

      </v-col> 
      <v-col cols="3">
        
      </v-col>      
    </v-row>
   
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ApothekeRechnung",
  props: {
    value: {
      type: Object,
    },
    filter: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
  data: () => ({
        rechnungsdatum: null,
        fromDateMenu: false,    
        fromDateMenuSepaMandatUnterschriebenAm: false,   
        minDate: "2022-06-01",
        rechnungErstellen: { 
          id: null, 
          rechungsdatum: null 
        },
        loginWait: false,
        rechnungstypen: ["Lizenz", "Einrichtung"],
        rechnungstyp: null,
        headersTable: [
          {
          text: "Datum",
          sortable: true,
          value: 'datum',
        },
        {
          text: "Betrag",
          sortable: true,
          value: 'betrag',
        }
      ],
      tableValues: [        
      ],
   }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    fromDateDisp() {
        if(this.rechnungsdatum) {
          const datum = new Date(Date.parse(this.rechnungsdatum));
          return datum.toLocaleDateString('de-DE');
        }

        return null;
    },
    fromDateDispSepaMandatUnterschriebenAm() {
        if(this.model.sepaMandatUnterschriebenAm) {
          const datum = new Date(Date.parse(this.model.sepaMandatUnterschriebenAm));
          return datum.toLocaleDateString('de-DE');
        }

        return null;
    },
  },
  methods: {
    async einrichtungsRechung() {
      this.loginWait = true;

      this.rechnungErstellen.id = this.model.id;
      this.rechnungErstellen.rechungsdatum = this.rechnungsdatum;

      try {
        const apiUrl = process.env.VUE_APP_API_URL;

        if (this.rechnungstyp == "Einrichtung") {
          let res = await axios.post(apiUrl + "/administration/createRechnungEinrichtung", this.rechnungErstellen);
          if (res.data === true) {
            this.loginWait = false;
          }
        }   
        else if (this.rechnungstyp == "Lizenz") {
          this.loginWait = false;
        }     
        else {
          this.loginWait = false;
        }
      } catch (err) {        
        console.log(err.response);        
      }
    }
  }
};
</script>